export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("app_at")
    ? JSON.parse(window.localStorage.getItem("app_at"))
    : {}

const setUser = user =>
  window.localStorage.setItem("app_at", JSON.stringify(user))

export const handleLogin = async ({ username, password }) => {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password })
  };
  const response = await fetch('/.netlify/functions/login', requestOptions);
  const data = await response.json();

  console.log(data)

  if (data.token) {
    setUser(data)

    return {
      code: 200
    }
  }
  return {
    code: 403,
    message: data.msg
  }
}

export const isLoggedIn = () => {
  const user = getUser()
  return !!user.token
}

export const logout = callback => {
  setUser({})
  callback()
}

export const getToken = callback => {
  const user = getUser()
  return user.token
}
